import React from 'react'

const Main = ({children}) => {
  return (
    <>
    {children}
    </>
    
  )
}

export default Main
