import React, { useState } from 'react'

const OurClientsSection = () => {

  const [readMore, setReadMore]= useState(false);

  return (
    <div className='text-center py-10'>
          <h1 className='font-md text-5xl'>Our <span className='font-light'>Clients</span></h1>
          <hr className='border-2 border-red-600  w-[5%] flex justify-center  mx-auto mt-5'/>
          <div className=' '>
            {
              readMore  ? (
                <div className='text-center space-y-3 flex mx-auto flex-col md:grid grid-cols-4 items-center justify-center   w-[70%] mx-auto my-10 gap-10'>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/Abott.png')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/Gautam.png')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/Nahar.jpeg')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/SCPL.png')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/vimal.png')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/Lupin.jpeg')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/US.jpeg')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/relianc3e.png')}/>
                  <img  className='mx-auto' alt="logos of clients"src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVdM4vrgvoLXkkVdUBRWPszirrkF3bKqM-aA&s'}/>
            <img className='mx-auto'  alt="logos of clients"src={'https://lpgcylinder.in/wp-content/uploads/2019/08/PI-lOGO.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://rajshreefabrics.com/wp-content/uploads/2021/06/cropped-Logo-1-01-217x64.png'}/>
            <img className='mx-auto'  alt="logos of clients"src={'https://suryatextech.com/images/surya-textech-logo.png'}/>
            <img  alt="logos of clients"src={'https://ruchirapackaging.com/assets/images/Logo_page-0001__1_-removebg-preview.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_ucA1a3ybCKkmzcSM-B07_ou_itcQcl_D3g&s'}/>

            <img  className='mx-auto' alt="logos of clients"src={'https://www.vguard.in/ui/client/images/vguard-logo.jpg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'http://www.suryalakshmi.com/images/logo.jpg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://parijatagrochemicals.com/wp-content/uploads/2019/02/PARIJAT-TOP-LOGO-1.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://welpackind.com/wp-content/uploads/2022/12/logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://kitply.com/wp-content/uploads/2023/12/logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.indoco.com/images/indoco-logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.tctl.in/wp-content/uploads/2022/11/logo-1-1-TM-1.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.okaya.in/storage/images/setting/header1698753213.webp'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.winsometextile.com/images/logo1.jpg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.livguard.com/static-assets/icons/logo-light.svg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://ushayarns.com/wp-content/uploads/2023/07/logo-usha-yarns-ltd.svg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.rrkabel.com/wp-content/uploads/2023/03/RR.svg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'http://wellonwater.com/assets/custweb/images/logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.avonmeters.com/assets/images/avon-logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.crompton.co.in/cdn/shop/files/logo.svg?v=1681391912&width=500'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR60JcXvaeHZ9YMbfK3-ij3coT9Fe40Fcib0w&s'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.godrej.com/image/logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.khaitan.com/wp-content/uploads/2022/03/Logo.gif'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.arvind.com/sites/all/themes/arvind/images/logoBlack.svg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.ashirwadgroup.in/assets/img/logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://bhumitex.com/images/bhumi-tex.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.dhunseripolyfilms.com/wp-content/uploads/2023/05/dhunseri-polyfilms-logo.jpg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://exzod.com/wp-content/uploads/2021/06/exzod_logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.zaubacorp.com/sites/default/themes/bootstrap_subtheme/images/logo-Zauba.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.banswarasyntex.com/wp-content/uploads/2019/05/logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.alokind.com/assets/img/logo.svg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.amritexports.com/assets/img/logo.png'}/>

            <img  className='mx-auto' alt="logos of clients"src={'https://www.riekepackaging.com/images/content/rieke-logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.gardenvareli.com/wp-content/uploads/2022/04/Garden-logo-1.jpg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.velmoctools.com/assets/images/velmoc-professional-2.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'http://www.partapgroup.in/images/co-logo.png'}/>

            <img  className='mx-auto' alt="logos of clients"src={'https://proecenergy.com/wp-content/uploads/2024/03/Proec-Energy-Logo-1.png'}/>
            <img alt="logos of clients" src={'http://ravimetalproducts.com/images/logo.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://saatvikgroup.com/wp-content/uploads/cropped-Saatvik-Solar-Logo-v2024-01-2048x713.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://img.saurenergy.com/img/saurenergy.png'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://www.dnb.com/content/dam/english/image-library/Modernization/logos/internal/logo-dnb.svg'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://lh5.googleusercontent.com/proxy/Qa5RR6b6CW0DQ5OFbKgOypl6dC_XsvDgkxkpit7IJ3JFLMjB_hAxTSGm-MpJ1QukbKDVlo8EK8djLpoa6fpavMigiqSvsrkoQ1K-EOd_'}/>
            <img  className='mx-auto' alt="logos of clients"src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUW-g1nT5BJDSmUsOa4pL91LaqX23byXwIWw&s'}/>
            

                  </div>
              ) :(<div className='text-center space-y-3 md:grid grid-cols-4 items-center  w-[70%] mx-auto my-10 gap-10'>
                <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/Abott.png')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/Gautam.png')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/Nahar.jpeg')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/SCPL.png')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/vimal.png')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/Lupin.jpeg')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/US.jpeg')}/>
            <img  className='mx-auto' alt="logos of clients"src={require('../../Assets/relianc3e.png')}/>
              </div>)
            }
           <button onClick={()=>setReadMore(!readMore) } className=' p-2 border border-white shadow-sm bg-red-600 text-xl font-semibold text-white animate-pulse'>
            {readMore ? "See Less Clients":"See All Clients"}
           </button>
            



            

          </div>
        </div>
  )
}

export default OurClientsSection
